import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { IntlProvider } from 'react-intl';
import Layout from './components/Layout';
import Home from './pages/Home';
import Gigs from './pages/Gigs';
import Tasks from './pages/Tasks';
import { theme, darkTheme } from './theme';
import './i18n';
import enMessages from './localization/en.json';
import ruMessages from './localization/ru.json';
import i18n from './i18n';

const messages = {
  en: enMessages,
  ru: ruMessages,
};

const App: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark';
  });
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem('language');
    return savedLanguage || 'ru';
  });

  useEffect(() => {
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  useEffect(() => {
    localStorage.setItem('language', currentLanguage);
    i18n.changeLanguage(currentLanguage);
  }, [currentLanguage]);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const changeLanguage = (lang: string) => {
    setCurrentLanguage(lang);
  };

  return (
    <IntlProvider messages={messages[currentLanguage as keyof typeof messages]} locale={currentLanguage} defaultLocale="ru">
      <ThemeProvider theme={isDarkMode ? darkTheme : theme}>
        <CssBaseline />
        <Router>
          <Layout 
            toggleTheme={toggleTheme} 
            changeLanguage={changeLanguage} 
            currentLanguage={currentLanguage}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/gigs" element={<Gigs />} />
              <Route path="/tasks" element={<Tasks />} />
            </Routes>
          </Layout>
        </Router>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default App;
