import React from 'react';
import { Button, ButtonGroup, useMediaQuery, Theme } from '@mui/material';
import { useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import AssignmentIcon from '@mui/icons-material/Assignment';
import WorkIcon from '@mui/icons-material/Work';

const ToggleButtons: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <ButtonGroup variant="contained" sx={{ mb: 2 }}>
      <Button
        onClick={() => navigate('/gigs')}
        sx={{
          backgroundColor: location.pathname === '/gigs' ? 'primary.dark' : 'primary.main',
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
        }}
        startIcon={<WorkIcon />}
      >
        {isMobile ? intl.formatMessage({ id: 'home.freelancerAds.short' }) : intl.formatMessage({ id: 'home.freelancerAds' })}
      </Button>
      <Button
        onClick={() => navigate('/tasks')}
        sx={{
          backgroundColor: location.pathname === '/tasks' ? 'primary.dark' : 'primary.main',
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
        }}
        startIcon={<AssignmentIcon />}
      >
        {isMobile ? intl.formatMessage({ id: 'home.clientTasks.short' }) : intl.formatMessage({ id: 'home.clientTasks' })}
      </Button>
    </ButtonGroup>
  );
};

export default ToggleButtons;
