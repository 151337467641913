import React from 'react';
import { AppBar, Toolbar, Typography, Link, Box, IconButton, Container } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const iconColor = theme.palette.mode === 'dark' ? '#fff' : '#000';

  return (
    <AppBar position="static" color="default" elevation={0} component="footer" sx={{ top: 'auto', bottom: 0, borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
      <Container maxWidth="lg">
        <Toolbar sx={{ justifyContent: 'space-between', padding: '0 24px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton aria-label="Telegram Channel" sx={{ mr: 1, color: iconColor }}>
              <TelegramIcon />
            </IconButton>
            <IconButton aria-label="Telegram Bot" sx={{ mr: 1, color: iconColor }}>
              <SmartToyIcon />
            </IconButton>
            <IconButton aria-label="X Account" sx={{ mr: 1, color: iconColor }}>
              <TwitterIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Link href="#" color="inherit" underline="none" sx={{ mx: 1 }}>
              <Typography variant="body2">{t('footer.forFreelancers')}</Typography>
            </Link>
            <Link href="#" color="inherit" underline="none" sx={{ mx: 1 }}>
              <Typography variant="body2">{t('footer.forClients')}</Typography>
            </Link>
            <Link href="#" color="inherit" underline="none" sx={{ mx: 1 }}>
              <Typography variant="body2">{t('footer.aboutGigSquare')}</Typography>
            </Link>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Footer;
