import React, { useState, useCallback } from 'react';
import { Box, IconButton, List, ListItem, ListItemText, Collapse, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Category, categories } from './Sidebar';
import { useTranslation } from 'react-i18next';

const MobileSidebar: React.FC = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [openCategory, setOpenCategory] = useState<string | null>(null);
  const [openSubcategory, setOpenSubcategory] = useState<string | null>(null);

  const handleCategoryClick = useCallback((categoryKey: string) => {
    setOpenCategory(prevOpenCategory => prevOpenCategory === categoryKey ? null : categoryKey);
    setOpenSubcategory(null);
  }, []);

  const handleSubcategoryClick = useCallback((subcategoryKey: string) => {
    setOpenSubcategory(prevOpenSubcategory => prevOpenSubcategory === subcategoryKey ? null : subcategoryKey);
  }, []);

  const toggleDrawer = useCallback((open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsOpen(open);
  }, []);

  return (
    <Box>
      <IconButton onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
        >
          <List>
            {categories.map((category: Category) => (
              <React.Fragment key={category.key}>
                <ListItem button onClick={() => handleCategoryClick(category.key)}>
                  <ListItemText primary={t(`categories.${category.key}`)} />
                  {openCategory === category.key ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openCategory === category.key} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {category.subcategories.map((subcategory) => (
                      <React.Fragment key={subcategory.key}>
                        <ListItem button onClick={() => handleSubcategoryClick(subcategory.key)} sx={{ pl: 4 }}>
                          <ListItemText primary={t(`subcategories.${subcategory.key}`)} />
                          {openSubcategory === subcategory.key ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openSubcategory === subcategory.key} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {subcategory.items.map((item: string) => (
                              <ListItem button key={item} sx={{ pl: 6 }}>
                                <ListItemText primary={t(`items.${item}`)} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </React.Fragment>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MobileSidebar;
