import React, { useState, useEffect } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

// Импортируем иконки
import BrushIcon from '@mui/icons-material/Brush';
import MovieIcon from '@mui/icons-material/Movie';
import CodeIcon from '@mui/icons-material/Code';
import CampaignIcon from '@mui/icons-material/Campaign';
import TranslateIcon from '@mui/icons-material/Translate';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StorefrontIcon from '@mui/icons-material/Storefront';

export interface Category {
  key: string;
  icon: React.ReactElement;
  subcategories: {
    key: string;
    items: string[];
  }[];
}

export const categories: Category[] = [
  {
    key: "graphics_and_design",
    icon: <BrushIcon />,
    subcategories: [
      {
        key: "logo_and_brand_identity",
        items: ["logo_design", "brand_style_guides", "business_cards_and_stationery", "fonts_and_typography", "logo_maker_tool"]
      },
      {
        key: "art_and_illustration",
        items: ["illustration", "ai_artists", "ai_avatar_design", "children_book_illustration", "portraits_and_caricatures", "cartoons_and_comics", "pattern_design", "tattoo_design", "storyboards", "nft_art"]
      },
      // ... добавьте остальные подкатегории
    ]
  },
  {
    key: "video_and_animation",
    icon: <MovieIcon />,
    subcategories: []
  },
  {
    key: "programming",
    icon: <CodeIcon />,
    subcategories: []
  },
  {
    key: "digital_marketing",
    icon: <CampaignIcon />,
    subcategories: []
  },
  {
    key: "writing_and_translation",
    icon: <TranslateIcon />,
    subcategories: []
  },
  {
    key: "personal_growth",
    icon: <SelfImprovementIcon />,
    subcategories: []
  },
  {
    key: "music_and_audio",
    icon: <MusicNoteIcon />,
    subcategories: []
  },
  {
    key: "photography",
    icon: <CameraAltIcon />,
    subcategories: []
  },
  {
    key: "consulting",
    icon: <BusinessCenterIcon />,
    subcategories: []
  },
  {
    key: "ai_services",
    icon: <SmartToyIcon />,
    subcategories: []
  },
  {
    key: "finance",
    icon: <AccountBalanceIcon />,
    subcategories: []
  },
  {
    key: "business",
    icon: <StorefrontIcon />,
    subcategories: []
  }
];

const Sidebar: React.FC = () => {
  const [openCategory, setOpenCategory] = useState<string | null>(null);
  const [openSubcategory, setOpenSubcategory] = useState<string | null>(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // This effect will run when the language changes
  }, [i18n.language]);

  const handleCategoryClick = (categoryKey: string) => {
    setOpenCategory(openCategory === categoryKey ? null : categoryKey);
    setOpenSubcategory(null);
  };

  const handleSubcategoryClick = (subcategoryKey: string) => {
    setOpenSubcategory(openSubcategory === subcategoryKey ? null : subcategoryKey);
  };

  return (
    <Box sx={{ width: 270, flexShrink: 0, mr: 2, overflowY: 'auto', height: '100%' }}>
      {categories.map((category) => (
        <React.Fragment key={category.key}>
          <ListItem
            button
            onClick={() => handleCategoryClick(category.key)}
            sx={{ mb: 1, cursor: 'pointer' }}
          >
            <ListItemIcon>{category.icon}</ListItemIcon>
            <ListItemText primary={t(`categories.${category.key}`)} />
            {openCategory === category.key ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openCategory === category.key} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {category.subcategories.map((subcategory) => (
                <React.Fragment key={subcategory.key}>
                  <ListItem button onClick={() => handleSubcategoryClick(subcategory.key)} sx={{ pl: 4 }}>
                    <ListItemText primary={t(`subcategories.${subcategory.key}`)} />
                    {openSubcategory === subcategory.key ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openSubcategory === subcategory.key} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {subcategory.items.map((item) => (
                        <ListItem button key={item} sx={{ pl: 6 }}>
                          <ListItemText primary={t(`items.${item}`)} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      ))}
    </Box>
  );
};

export default Sidebar;
