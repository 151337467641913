import React, { useState, useRef, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Menu, MenuItem } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import LanguageIcon from '@mui/icons-material/Language';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import '../styles/LogoAnimation.css';

interface NavbarProps {
  toggleTheme: () => void;
  changeLanguage: (lang: string) => void;
  currentLanguage: string;
  setSearchVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Navbar: React.FC<NavbarProps> = ({ toggleTheme, changeLanguage, currentLanguage, setSearchVisible }) => {
  const theme = useTheme();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const logoRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (logoRef.current) {
        const rect = logoRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const width = rect.width;
        const percentage = Math.max(0, Math.min(100, (x / width) * 100));
        logoRef.current.style.setProperty('--mouse-x', `${percentage}%`);
      }
    };

    const handleMouseLeave = () => {
      if (logoRef.current) {
        logoRef.current.style.setProperty('--mouse-x', '0%');
      }
    };

    const logoElement = logoRef.current;
    if (logoElement) {
      logoElement.addEventListener('mousemove', handleMouseMove);
      logoElement.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (logoElement) {
        logoElement.removeEventListener('mousemove', handleMouseMove);
        logoElement.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (lang: string) => {
    changeLanguage(lang);
    handleClose();
  };

  const handleSearchToggle = () => {
    setSearchVisible(prev => !prev);
  };

  return (
    <AppBar position="fixed" color="default" elevation={0} sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
      <Toolbar sx={{ justifyContent: 'space-between', maxWidth: 'lg', width: '100%', margin: '0 auto', padding: '0 24px' }}>
        <Typography variant="h6" component={RouterLink} to="/" className="logo-text">
          <span ref={logoRef}>GigSquare</span>
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color="inherit" onClick={handleSearchToggle} sx={{ mx: 1 }}>
            <SearchIcon />
          </IconButton>
          <IconButton color="inherit" onClick={toggleTheme} sx={{ mx: 1 }}>
            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          <IconButton color="inherit" onClick={handleMenu} sx={{ mx: 1 }}>
            <LanguageIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleLanguageChange('ru')} selected={currentLanguage === 'ru'}>Русский</MenuItem>
            <MenuItem onClick={() => handleLanguageChange('en')} selected={currentLanguage === 'en'}>English</MenuItem>
          </Menu>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Button 
              color="inherit" 
              startIcon={<TelegramIcon sx={{ color: '#0088cc' }} />} 
              sx={{ ml: 1 }}
            >
              {intl.formatMessage({ id: 'navbar.login' })}
            </Button>
          </Box>
          <IconButton 
            color="inherit" 
            sx={{ 
              display: { xs: 'flex', sm: 'none' }, 
              ml: 1,
              '& .MuiSvgIcon-root': { color: '#0088cc' }
            }}
          >
            <TelegramIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
