import React, { useState } from 'react';
import { Box, IconButton, Tooltip, Menu, MenuItem } from '@mui/material';
import { useIntl } from 'react-intl';
import SortIcon from '@mui/icons-material/Sort';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';

const ViewControls: React.FC = () => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortBy, setSortBy] = useState('latest');
  const [viewMode, setViewMode] = useState<'card' | 'list'>('card');

  const handleSortClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setAnchorEl(null);
  };

  const handleSortChange = (value: string) => {
    setSortBy(value);
    handleSortClose();
  };

  const toggleViewMode = () => {
    setViewMode(viewMode === 'card' ? 'list' : 'card');
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={intl.formatMessage({ id: viewMode === 'card' ? 'view.card' : 'view.list' })}>
        <IconButton onClick={toggleViewMode}>
          {viewMode === 'card' ? <ViewModuleIcon /> : <ViewListIcon />}
        </IconButton>
      </Tooltip>
      <Tooltip title={intl.formatMessage({ id: `sort.${sortBy}` })}>
        <IconButton onClick={handleSortClick}>
          <SortIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleSortClose}
      >
        <MenuItem onClick={() => handleSortChange('latest')} selected={sortBy === 'latest'}>
          {intl.formatMessage({ id: 'sort.latest' })}
        </MenuItem>
        <MenuItem onClick={() => handleSortChange('popular')} selected={sortBy === 'popular'}>
          {intl.formatMessage({ id: 'sort.popular' })}
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ViewControls;
