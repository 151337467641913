import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#f0f4f8', // Светлый серо-голубой цвет для верхнего меню в светлой теме
    },
    secondary: {
      main: '#ff5722', // Оранжевый цвет для светлой темы
    },
    background: {
      default: '#f5f5f5', // Светлый фон
      paper: '#ffffff', // Цвет бумаги
    },
    text: {
      primary: '#333333', // Темный цвет текста для светлой темы
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#bb86fc', // Фиолетовый цвет для темной темы
    },
    secondary: {
      main: '#03dac6', // Бирюзовый цвет для темной темы
    },
    background: {
      default: '#121212', // Темный фон
      paper: '#1e1e1e', // Цвет бумаги
    },
  },
});

export { theme, darkTheme };