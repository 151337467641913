import React from 'react';
import { Box } from '@mui/material';

const Gigs: React.FC = () => {
  return (
    <Box>
      {/* Содержимое страницы Gigs */}
      {/* Здесь должен быть ваш основной контент для страницы Gigs */}
      {/* Убедитесь, что здесь нет дублирования ViewControls */}
    </Box>
  );
};

export default Gigs;
