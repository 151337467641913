import React from 'react';
import { Box } from '@mui/material';

const Tasks: React.FC = () => {
  return (
    <Box>
      {/* Содержимое страницы Tasks */}
      {/* Здесь должен быть только основной контент для страницы Tasks */}
      {/* Не добавляйте сюда ViewControls или ToggleButtons */}
    </Box>
  );
};

export default Tasks;
