import React from 'react';
import { Box, Container, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import MobileSidebar from './MobileSidebar';
import ToggleButtons from './ToggleButtons';
import ViewControls from './ViewControls';
import Footer from './Footer';  // Добавьте этот импорт

interface LayoutProps {
  children: React.ReactNode;
  toggleTheme: () => void;
  changeLanguage: (lang: string) => void;
  currentLanguage: string;
}

const Layout: React.FC<LayoutProps> = ({ children, toggleTheme, changeLanguage, currentLanguage }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Navbar 
        toggleTheme={toggleTheme}
        changeLanguage={changeLanguage}
        currentLanguage={currentLanguage}
        setSearchVisible={() => {}}
      />
      <Container 
        maxWidth="lg" 
        sx={{ 
          mt: '80px',
          mb: 4, 
          flex: 1, 
          display: 'flex', 
          flexDirection: 'column',
          padding: '0 24px'
        }}
      >
        {isMobile ? (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
              <MobileSidebar />
              <ToggleButtons />
              <ViewControls />
            </Box>
            <Box component="main" sx={{ flexGrow: 1 }}>
              {children}
            </Box>
          </>
        ) : (
          <Box sx={{ display: 'flex', flex: 1 }}>
            <Sidebar />
            <Box component="main" sx={{ flexGrow: 1, ml: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <ToggleButtons />
                <ViewControls />
              </Box>
              {children}
            </Box>
          </Box>
        )}
      </Container>
      <Footer />  {/* Добавьте этот компонент */}
    </Box>
  );
};

export default Layout;
